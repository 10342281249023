import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AddClass, GetRootParentNode, HasClass, IsInsideShadowDom, RemoveClass, } from '../utils/dom';
// 列宽度拖动
import utils from '../utils/utils';
let DragWidthMixin = class DragWidthMixin extends Vue {
    draggingColumn = null; // 当前拖动的列
    isDragging = false; // 是否正在拖动
    draggingStartX = 0; // 拖动开始横坐标
    draggingEndX = 0; // 拖动结束横坐标
    minColumnWidth = 15; // 列允许拖动后的最小宽度
    handleTitleMouseMove(event, column) {
        const that = this;
        if (!that.columnWidthDrag) {
            return false;
        }
        let target, rect;
        if (this.isDragging) {
            this.setDragLinePosition(event);
        }
        // 复杂表头，多列时不允许拖动
        if (Array.isArray(column)) {
            if (column.length > 1) {
                return false;
            }
            else {
                column = column[0];
            }
        }
        // 最后一列不允许拖动
        /*if (this.internalColumns[this.internalColumns.length - 1].field === column) {
                 return false;
                 }*/
        if (!that.showVerticalBorder) {
            return false;
        }
        target = event.target;
        while (target &&
            ((target.className && !HasClass(target, 'v-table-title-cell')) ||
                !target.className)) {
            target = target.parentNode;
        }
        rect = target?.getBoundingClientRect();
        if (!rect) {
            return false;
        }
        const bodyStyle = document.body.style;
        if (rect.width >= this.minColumnWidth && rect.right - event.pageX < 10) {
            if (!this.isDragging) {
                // 拖动中不设置
                this.draggingColumn = that.internalColumns.find((x) => x.field === column);
            }
            bodyStyle.cursor = 'col-resize';
        }
        else {
            if (!this.isDragging) {
                // 拖动中不设置
                this.draggingColumn = null;
                bodyStyle.cursor = '';
            }
        }
    }
    handleTitleMouseOut() {
        if (!this.isDragging) {
            document.body.style.cursor = '';
        }
    }
    handleTitleMouseDown(event, column) {
        const that = this;
        if (!this.draggingColumn || !that.showVerticalBorder) {
            return false;
        }
        this.isDragging = true;
        this.draggingStartX = event.clientX;
        this.setDragLinePosition(event);
        // 处理事件绑定失效，如果在shadow dom内则调用 root element执行绑定
        let rootNode = document;
        const compEl = this.$el; // 组件 dom
        const isInsideShadowDom = IsInsideShadowDom(compEl);
        if (isInsideShadowDom) {
            rootNode = GetRootParentNode(compEl);
        }
        if (!rootNode) {
            return;
        }
        // 处理拖动列头时文字被选中
        rootNode.onselectstart = function () {
            return false;
        };
        rootNode.ondragstart = function () {
            return false;
        };
        utils.bind(document, 'mousemove', this.handleDragMouseMove);
        utils.bind(document, 'mouseup', this.handleDragMouseUp);
    }
    handleDragMouseMove(e) {
        if (!this.isDragging) {
            return false;
        }
        this.setDragLinePosition(e);
    }
    setDragLinePosition(e) {
        const tableLeft = utils.getViewportOffset(this.$el).left, dragLine = this.$el.querySelector('.v-table-drag-line'), clientX = e.clientX;
        if (this.draggingColumn.width + (clientX - this.draggingStartX) <=
            this.minColumnWidth) {
            return;
        }
        dragLine.style.left = clientX - tableLeft + 'px';
    }
    // 拖动时mouseup
    handleDragMouseUp(e) {
        const that = this;
        if (!this.isDragging) {
            return false;
        }
        this.draggingEndX = e.clientX;
        const differ = this.draggingEndX - this.draggingStartX;
        // 差值大于1才处理
        if (Math.abs(differ) > 1) {
            let draggingColumn = this.draggingColumn;
            if (draggingColumn.width + differ < this.minColumnWidth) {
                draggingColumn.width = this.minColumnWidth;
            }
            else {
                draggingColumn.width += differ;
            }
            this.$emit('on-drag-change-width-success', { ...draggingColumn });
        }
        let rightViewBody = this.$el.querySelector('.v-table-rightview .v-table-body'), rightViewFooter = this.$el.querySelector('.v-table-rightview .v-table-footer'), hasTableFooter = that.hasTableFooter;
        if (that.totalColumnsWidth < that.internalWidth) {
            if (!hasTableFooter) {
                rightViewBody.style.overflowX = 'hidden';
                RemoveClass(rightViewBody, 'v-table-rightview-special-border');
                rightViewBody.classList.remove('v-table-rightview-special-border');
            }
            else {
                rightViewFooter.style.overflowX = 'hidden';
            }
        }
        else {
            if (!hasTableFooter) {
                rightViewBody.style.overflowX = 'scroll';
                if (!that.hasFrozenColumn) {
                    AddClass(rightViewBody, 'v-table-rightview-special-border');
                }
            }
            else {
                rightViewFooter.style.overflowX = 'scroll';
            }
        }
        this.draggingColumn = null;
        document.body.style.cursor = '';
        this.isDragging = false;
        // @ts-ignore
        this.syncLeftRightTableRowHeight();
        // 处理拖动列头时文字被选中
        let rootNode = document;
        const compEl = this.$el; // 组件 dom
        const isInsideShadowDom = IsInsideShadowDom(compEl);
        if (isInsideShadowDom) {
            rootNode = GetRootParentNode(compEl);
        }
        if (!rootNode) {
            return;
        }
        rootNode.onselectstart = function () {
            return true;
        };
        rootNode.ondragstart = function () {
            return true;
        };
        utils.unbind(rootNode, 'mousemove', this.handleDragMouseMove);
        utils.unbind(rootNode, 'mouseup', this.handleDragMouseUp);
    }
};
DragWidthMixin = __decorate([
    Component
], DragWidthMixin);
export default DragWidthMixin;
