var PiCommonColumnFilter_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { Button, Table } from 'iview';
import template from './pi-common-column-filter.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-common-column-filter.component.scss';
import { PiBaseModal, VueBase } from 'bc$/main';
let PiCommonColumnFilter = PiCommonColumnFilter_1 = class PiCommonColumnFilter extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiCommonColumnFilter';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiCommonColumnFilter_1?.NAME,
        author: 'dhh',
        title: '列过滤弹窗',
        version: require('@/../package.json').version,
        updateDate: '2024-01-12',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 是否显示模态框
     */
    value;
    /*
     * 所有列
     */
    columns;
    //#endregion
    //#region data
    /**
     * Modal显示/隐藏
     */
    isShow = false;
    table = {
        isLoading: false,
        /**
         * 选中的行
         */
        selectedRow: [],
        rows: [],
        columns: [
            {
                width: 100,
                titleAlign: 'center',
                align: 'center',
                type: 'selection',
            },
            {
                title: '列名称',
                key: 'title',
                resizable: true,
            },
        ],
    };
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiCommonColumnFilter_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region 函数类属性
    //#endregion
    //#region watch
    'watch.isShow'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.$emit('input', newVal);
    }
    'watch.value'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.isShow = newVal;
    }
    'watch.columns'(val) {
        const rows = val || [];
        this.table.selectedRow = rows.filter((item) => item._checked);
        this.table.rows.splice(0, this.table.rows.length, ...rows);
    }
    //#endregion
    //#region methods
    /**
     * 当前选中表格列
     */
    selectGroupChange(data) {
        this.table.selectedRow = data || [];
    }
    /**
     * 确定保存
     */
    onSave() {
        const rows = this.table.selectedRow || [];
        const fields = rows.map((item) => item.field);
        const columns = this.columns || [];
        const configColumnList = columns.map((item) => {
            return {
                columnKey: item.field,
                columnName: item.title,
                columnShow: item._disabled || fields.includes(item.field),
            };
        });
        this.$emit('on-ok', configColumnList);
        this.isShow = false;
    }
};
__decorate([
    Prop({
        type: Boolean,
        required: true,
    })
], PiCommonColumnFilter.prototype, "value", void 0);
__decorate([
    Prop({
        type: Array,
        required: true,
    })
], PiCommonColumnFilter.prototype, "columns", void 0);
__decorate([
    Watch('isShow')
], PiCommonColumnFilter.prototype, "watch.isShow", null);
__decorate([
    Watch('value', { immediate: true })
], PiCommonColumnFilter.prototype, "watch.value", null);
__decorate([
    Watch('columns', { immediate: true, deep: true })
], PiCommonColumnFilter.prototype, "watch.columns", null);
PiCommonColumnFilter = PiCommonColumnFilter_1 = __decorate([
    Component({
        components: {
            PiBaseModal,
            Button,
            Table,
        },
        template,
    })
], PiCommonColumnFilter);
export default PiCommonColumnFilter;
