import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
/**
 * 全局动态列复用逻辑
 */
let PiCustomColumnMixin = class PiCustomColumnMixin extends Vue {
    /**
     * 最终显示的列
     */
    columnList = [];
    /**
     * 自定义弹窗数据行
     */
    customColumnRow = [];
    customColumnList = [];
    /**
     * 获取动态列之后绑定显示列
     * @param list
     * @returns
     */
    dynamicColumnCallBack(columns = [], list) {
        const fieldList = [];
        const customColumnRow = [];
        columns.forEach((item) => {
            const field = item.field || '';
            const column = list.find((i) => i.columnKey === field);
            const _disabled = item._disabled || false;
            const show = list?.length ? column?.columnShow || false : true;
            if (show || _disabled) {
                fieldList.push(field);
            }
            // 弹窗不需要处理select
            if (item.type === 'selection') {
                return;
            }
            customColumnRow.push({
                field: field,
                title: item.title || '',
                _checked: show,
                _disabled: _disabled,
            });
        });
        this.customColumnRow = customColumnRow;
        this.columnList = columns.filter((item) => !(item.field || '').length || fieldList.includes(item.field || ''));
    }
};
PiCustomColumnMixin = __decorate([
    Component
], PiCustomColumnMixin);
export default PiCustomColumnMixin;
