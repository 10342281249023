import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases';
let TableAdjustMixin = class TableAdjustMixin extends VueBase {
    /**
     * 是否挂载时自动调整表格视窗
     */
    isAdjustBodyViewHeightOnMounted;
    /**
     * 是否自动调节行高
     */
    isAutoResizeCellHeight;
    /**
     * 固定表格体视窗高度
     */
    fixTableBodyViewHeight = 0;
    /**
     * 延迟执行函数
     */
    delayHandlerFn(timerName, fn, delay = 100) {
        this.resetTimeOut(timerName, fn, delay);
    }
    /**
     * 调整表格体视窗高度
     * PS: 处理表格内部高度不充满容器问题
     * @param isDelay 是否延迟执行
     */
    adjustBodyViewHeight(isDelay = true) {
        const fn = () => {
            const totalHeight = this.$refs.tableRoot
                .clientHeight;
            const titleHeight = this.$refs.tableRightHead?.clientHeight ?? 0;
            const bodyHeight = totalHeight - titleHeight + 1;
            this.fixTableBodyViewHeight = bodyHeight;
        };
        isDelay ? this.delayHandlerFn('adjustBodyViewHeight', fn) : this.$nextTick(fn);
    }
    /**
     * 同步左右表格行高
     */
    syncLeftRightTableRowHeight() {
        //开启自动调节行高,且有冻结列时取左右表格当前行的最大高度为行高度
        this.delayHandlerFn('syncLeftRightTableRowHeight', () => {
            // @ts-ignore
            if (this.isAutoResizeCellHeight && this.getFrozenColumnsFields.length > 0) {
                const leftViewBodyRows = this.$refs
                    .tableLeftBody.querySelectorAll('.v-table-row');
                const rightViewBodyRows = this.$refs
                    .tableRightViewBody.querySelectorAll('.v-table-row');
                rightViewBodyRows.forEach((dom, index) => {
                    const leftHeight = leftViewBodyRows[index].clientHeight;
                    const rightHeight = dom.clientHeight;
                    const isLeftDirection = leftHeight < rightHeight;
                    const height = isLeftDirection ? rightHeight : leftHeight;
                    isLeftDirection
                        ? (leftViewBodyRows[index].style.height = `${height}px`)
                        : (rightViewBodyRows[index].style.height = `${height}px`);
                });
            }
        });
    }
    mounted() {
        this.isAdjustBodyViewHeightOnMounted
            && this.adjustBodyViewHeight();
        this.syncLeftRightTableRowHeight();
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], TableAdjustMixin.prototype, "isAdjustBodyViewHeightOnMounted", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], TableAdjustMixin.prototype, "isAutoResizeCellHeight", void 0);
TableAdjustMixin = __decorate([
    Component
], TableAdjustMixin);
export default TableAdjustMixin;
